var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('loading'):(!_vm.subPermission.store || !_vm.subPermission.update)?_c('content-not-view'):_c('div',[_c('b-card',{staticClass:"sticky-header-control"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"md":"6"}},[_c('h3',{staticClass:"text-primary m-0 p-0"},[_vm._v(_vm._s(_vm.$t("g.addEquipment"))+" |")]),_c('b-breadcrumb',{attrs:{"items":_vm.breadCrumbItems}})],1),_c('b-col',{staticClass:"justify-content-end d-flex",attrs:{"md":"6"}},[_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(_vm._s(_vm.$t("g.submit")))])],1)],1)],1),_c('validation-observer',{ref:"EquipmentForm"},[_c('b-form',[_c('b-card',{attrs:{"title":_vm.$t('g.basicInformations')}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.equipmentName')}},[_c('validation-provider',{attrs:{"name":"equipment name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.EnterTheEquipmentNameHere')},model:{value:(_vm.form_data.name),callback:function ($$v) {_vm.$set(_vm.form_data, "name", $$v)},expression:"form_data.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.equipmentID')}},[_c('validation-provider',{attrs:{"name":"equipment ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.enterTheEquipmentIdHere')},model:{value:(_vm.form_data.identifier),callback:function ($$v) {_vm.$set(_vm.form_data, "identifier", $$v)},expression:"form_data.identifier"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.serialNumber')}},[_c('validation-provider',{attrs:{"name":"serial number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.enterTheSerialNumberHere')},model:{value:(_vm.form_data.reference_number),callback:function ($$v) {_vm.$set(_vm.form_data, "reference_number", $$v)},expression:"form_data.reference_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.theBrandOrModel')}},[_c('validation-provider',{attrs:{"name":"brand or model","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.enterTheBrandOrModelHere')},model:{value:(_vm.form_data.brand),callback:function ($$v) {_vm.$set(_vm.form_data, "brand", $$v)},expression:"form_data.brand"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.category')}},[_c('validation-provider',{attrs:{"name":"category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.categories_options,"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"state":errors.length > 0 ? false : null,"text-field":"name","value-field":"id"},model:{value:(_vm.form_data.category_id),callback:function ($$v) {_vm.$set(_vm.form_data, "category_id", $$v)},expression:"form_data.category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.countryOfOrigin')}},[_c('validation-provider',{attrs:{"name":"country of origin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"state":errors.length > 0 ? false : null,"options":_vm.countries_options,"text-field":"name","value-field":"id"},model:{value:(_vm.form_data.country_id),callback:function ($$v) {_vm.$set(_vm.form_data, "country_id", $$v)},expression:"form_data.country_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1)],1)],1),_c('b-card',{attrs:{"title":_vm.$t('g.deviceSpecifications')}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.electricInputType')}},[_c('validation-provider',{attrs:{"name":"electric type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"options":_vm.electric_current_options,"state":errors.length > 0 ? false : null,"text-field":"name","value-field":"id"},model:{value:(_vm.form_data.electric_input_type_id),callback:function ($$v) {_vm.$set(_vm.form_data, "electric_input_type_id", $$v)},expression:"form_data.electric_input_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.deviceSpecifications')}},[_c('validation-provider',{attrs:{"name":"device specifications","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":_vm.$t('g.placeholder.enterTheDeviceSpecificationsHere'),"rows":"3","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.specs),callback:function ($$v) {_vm.$set(_vm.form_data, "specs", $$v)},expression:"form_data.specs"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.environmentalConditionsOfOperation')}},[_c('validation-provider',{attrs:{"name":"environmental conditions of operation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":_vm.$t(
                      'g.placeholder.enterTheEnvironmentalConditionsOfOperationHere'
                    ),"rows":"3","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.environmental_conditions),callback:function ($$v) {_vm.$set(_vm.form_data, "environmental_conditions", $$v)},expression:"form_data.environmental_conditions"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.standardSpecificationsForTests')}},[_c('validation-provider',{attrs:{"name":"standard specifications for tests","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":_vm.$t(
                      'g.placeholder.enterThestandardSpecificationsForTestsHere'
                    ),"rows":"3","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.test_standards),callback:function ($$v) {_vm.$set(_vm.form_data, "test_standards", $$v)},expression:"form_data.test_standards"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.machineWorkingArea')}},[_c('validation-provider',{attrs:{"name":"machine working area","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":_vm.$t('g.placeholder.enterTheMachineWorkingAreaHere'),"rows":"3","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.working_area),callback:function ($$v) {_vm.$set(_vm.form_data, "working_area", $$v)},expression:"form_data.working_area"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.deviceAccessories')}},[_c('validation-provider',{attrs:{"name":"device accessories","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":_vm.$t('g.placeholder.enterThedeviceAccessoriesHere'),"rows":"3","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.device_attaches),callback:function ($$v) {_vm.$set(_vm.form_data, "device_attaches", $$v)},expression:"form_data.device_attaches"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1)],1)],1),_c('b-card',{staticClass:"calibration",attrs:{"title":_vm.$t('g.calibration_plan')}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.file')}},[_c('validation-provider',{attrs:{"name":"calibration plan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-file',{attrs:{"accept":_vm.$t('g.Choose_a_file_or_drop_it_here...'),"drop-placeholder":"Drop file here...","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.calibration_plan),callback:function ($$v) {_vm.$set(_vm.form_data, "calibration_plan", $$v)},expression:"form_data.calibration_plan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])}),_c('div',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(_vm.$t("g.Selected_file") + ": ")+" "+_vm._s(_vm.form_data.calibration_plan ? _vm.form_data.calibration_plan.name : "")+" ")])],1)],1),(_vm.isEdit)?_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"d-flex justify-content-start align-items-center h-100"},[_c('a',{staticClass:"text-primary",attrs:{"href":_vm.form_data.calibration_plan_path,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("g.viewDocument"))+" ")])])]):_vm._e()],1)],1),_c('b-card',{staticClass:"mb-10",attrs:{"title":_vm.$t('g.equipmentConditionAndSupply')}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.supplier')}},[_c('validation-provider',{attrs:{"name":"supplier","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"options":_vm.supplier_opitons,"state":errors.length > 0 ? false : null,"text-field":"name","value-field":"id"},model:{value:(_vm.form_data.supplier_id),callback:function ($$v) {_vm.$set(_vm.form_data, "supplier_id", $$v)},expression:"form_data.supplier_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.maintenanceDuration')}},[_c('validation-provider',{attrs:{"name":"maintenanceDuration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"options":_vm.maintenanceDuration_options,"state":errors.length > 0 ? false : null,"text-field":"name","value-field":"id"},model:{value:(_vm.form_data.maintenance_type_id),callback:function ($$v) {_vm.$set(_vm.form_data, "maintenance_type_id", $$v)},expression:"form_data.maintenance_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.dateOfArrival')}},[_c('validation-provider',{attrs:{"name":"date of arrival","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.arraival_date),callback:function ($$v) {_vm.$set(_vm.form_data, "arraival_date", $$v)},expression:"form_data.arraival_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.serviceEntryDate')}},[_c('validation-provider',{attrs:{"name":"service entry date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.serviceEntry_date),callback:function ($$v) {_vm.$set(_vm.form_data, "serviceEntry_date", $$v)},expression:"form_data.serviceEntry_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.operatingDate')}},[_c('b-form-datepicker',{model:{value:(_vm.form_data.working_date),callback:function ($$v) {_vm.$set(_vm.form_data, "working_date", $$v)},expression:"form_data.working_date"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.outOfServiceDate')}},[_c('b-form-datepicker',{model:{value:(_vm.form_data.out_of_service_date),callback:function ($$v) {_vm.$set(_vm.form_data, "out_of_service_date", $$v)},expression:"form_data.out_of_service_date"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }