<template>
  <loading v-if="isLoading" />

  <content-not-view v-else-if="!subPermission.store || !subPermission.update" />
  <div v-else>
    <b-card class="sticky-header-control">
      <!-- <div class="pr-0 pl-0 d-flex justify-content-between align-items-center"> -->
      <b-row>
        <b-col class="d-flex align-items-center" md="6">
          <h3 class="text-primary m-0 p-0">{{ $t("g.addEquipment") }} |</h3>
          <b-breadcrumb :items="breadCrumbItems" />
        </b-col>
        <b-col md="6" class="justify-content-end d-flex">
          <b-button
            @click.prevent="validationForm"
            type="submit"
            variant="primary"
            >{{ $t("g.submit") }}</b-button
          >
        </b-col>
      </b-row>
      <!-- </div> -->
    </b-card>
    <validation-observer ref="EquipmentForm">
      <b-form>
        <b-card :title="$t('g.basicInformations')">
          <b-row>
            <b-col md="4">
              <b-form-group :label="$t('g.equipmentName')">
                <validation-provider
                  #default="{ errors }"
                  name="equipment name"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.EnterTheEquipmentNameHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group :label="$t('g.equipmentID')">
                <validation-provider
                  #default="{ errors }"
                  name="equipment ID"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.identifier"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheEquipmentIdHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$t('g.serialNumber')">
                <validation-provider
                  #default="{ errors }"
                  name="serial number"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.reference_number"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheSerialNumberHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$t('g.theBrandOrModel')">
                <validation-provider
                  #default="{ errors }"
                  name="brand or model"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.brand"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheBrandOrModelHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$t('g.category')">
                <validation-provider
                  #default="{ errors }"
                  name="category"
                  rules="required"
                >
                  <b-form-select
                    v-model="form_data.category_id"
                    :options="categories_options"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :state="errors.length > 0 ? false : null"
                    text-field="name"
                    value-field="id"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$t('g.countryOfOrigin')">
                <validation-provider
                  #default="{ errors }"
                  name="country of origin"
                  rules="required"
                >
                  <b-form-select
                    v-model="form_data.country_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :state="errors.length > 0 ? false : null"
                    :options="countries_options"
                    text-field="name"
                    value-field="id"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card :title="$t('g.deviceSpecifications')">
          <b-row>
            <b-col md="4">
              <b-form-group :label="$t('g.electricInputType')">
                <validation-provider
                  #default="{ errors }"
                  name="electric type"
                  rules="required"
                >
                  <b-form-select
                    v-model="form_data.electric_input_type_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="electric_current_options"
                    :state="errors.length > 0 ? false : null"
                    text-field="name"
                    value-field="id"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group :label="$t('g.deviceSpecifications')">
                <validation-provider
                  #default="{ errors }"
                  name="device specifications"
                  rules="required"
                >
                  <b-form-textarea
                    id="textarea-default"
                    :placeholder="
                      $t('g.placeholder.enterTheDeviceSpecificationsHere')
                    "
                    v-model="form_data.specs"
                    rows="3"
                    :state="errors.length > 0 ? false : null"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$t('g.environmentalConditionsOfOperation')">
                <validation-provider
                  #default="{ errors }"
                  name="environmental conditions of operation"
                  rules="required"
                >
                  <b-form-textarea
                    id="textarea-default"
                    :placeholder="
                      $t(
                        'g.placeholder.enterTheEnvironmentalConditionsOfOperationHere'
                      )
                    "
                    v-model="form_data.environmental_conditions"
                    rows="3"
                    :state="errors.length > 0 ? false : null"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$t('g.standardSpecificationsForTests')">
                <validation-provider
                  #default="{ errors }"
                  name="standard specifications for tests"
                  rules="required"
                >
                  <b-form-textarea
                    id="textarea-default"
                    :placeholder="
                      $t(
                        'g.placeholder.enterThestandardSpecificationsForTestsHere'
                      )
                    "
                    v-model="form_data.test_standards"
                    rows="3"
                    :state="errors.length > 0 ? false : null"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$t('g.machineWorkingArea')">
                <validation-provider
                  #default="{ errors }"
                  name="machine working area"
                  rules="required"
                >
                  <b-form-textarea
                    id="textarea-default"
                    :placeholder="
                      $t('g.placeholder.enterTheMachineWorkingAreaHere')
                    "
                    v-model="form_data.working_area"
                    rows="3"
                    :state="errors.length > 0 ? false : null"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$t('g.deviceAccessories')">
                <validation-provider
                  #default="{ errors }"
                  name="device accessories"
                  rules="required"
                >
                  <b-form-textarea
                    id="textarea-default"
                    :placeholder="
                      $t('g.placeholder.enterThedeviceAccessoriesHere')
                    "
                    v-model="form_data.device_attaches"
                    rows="3"
                    :state="errors.length > 0 ? false : null"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card :title="$t('g.calibration_plan')" class="calibration">
          <b-row>
            <b-col md="6">
              <b-form-group :label="$t('g.file')">
                <validation-provider
                  #default="{ errors }"
                  name="calibration plan"
                  rules="required"
                >
                  <b-form-file
                    v-model="form_data.calibration_plan"
                    :accept="$t('g.Choose_a_file_or_drop_it_here...')"
                    drop-placeholder="Drop file here..."
                    :state="errors.length > 0 ? false : null"
                  ></b-form-file>
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
                <div class="mt-1">
                  {{ $t("g.Selected_file") + ": " }}
                  {{
                    form_data.calibration_plan
                      ? form_data.calibration_plan.name
                      : ""
                  }}
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="isEdit">
              <div
                class="d-flex justify-content-start align-items-center h-100"
              >
                <a
                  :href="form_data.calibration_plan_path"
                  class="text-primary"
                  target="_blank"
                >
                  {{ $t("g.viewDocument") }}
                </a>
              </div>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="mb-10" :title="$t('g.equipmentConditionAndSupply')">
          <b-row>
            <b-col md="4">
              <b-form-group :label="$t('g.supplier')">
                <validation-provider
                  #default="{ errors }"
                  name="supplier"
                  rules="required"
                >
                  <b-form-select
                    v-model="form_data.supplier_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="supplier_opitons"
                    :state="errors.length > 0 ? false : null"
                    text-field="name"
                    value-field="id"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Maintenance duration -->
            <b-col md="4">
              <b-form-group :label="$t('g.maintenanceDuration')">
                <validation-provider
                  #default="{ errors }"
                  name="maintenanceDuration"
                  rules="required"
                >
                  <b-form-select
                    v-model="form_data.maintenance_type_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="maintenanceDuration_options"
                    :state="errors.length > 0 ? false : null"
                    text-field="name"
                    value-field="id"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$t('g.dateOfArrival')">
                <validation-provider
                  #default="{ errors }"
                  name="date of arrival"
                  rules="required"
                >
                  <b-form-datepicker
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.arraival_date"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$t('g.serviceEntryDate')">
                <validation-provider
                  #default="{ errors }"
                  name="service entry date"
                  rules="required"
                >
                  <b-form-datepicker
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.serviceEntry_date"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$t('g.operatingDate')">
                <b-form-datepicker v-model="form_data.working_date" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$t('g.outOfServiceDate')">
                <b-form-datepicker v-model="form_data.out_of_service_date" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard,
  BFormDatepicker,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BBreadcrumb,
  BCardHeader,
  BFormTextarea,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
import store from "@/store/index";
import Loading from "@/views/components/logic/loading.vue";

export default {
  components: {
    BCard,
    BFormDatepicker,
    BRow,
    BCol,
    BFormGroup,
    ValidationProvider,
    BFormTextarea,
    BForm,
    ValidationObserver,
    BFormInput,
    BButton,
    BBreadcrumb,
    BCardHeader,
    BFormSelect,
    BFormCheckbox,
    BFormFile,
    ContentNotView,
    Loading,
  },
  data() {
    return {
      form_data: {
        name: null,
        identifier: null,
        brand: null,
        reference_number: null,
        category_id: null,
        country_id: null,
        supplier_id: null,
        maintenance_type_id: null,
        electric_input_type_id: null,
        specs: null,
        environmental_conditions: null,
        test_standards: null,
        working_area: null,
        device_attaches: null,
        arraival_date: null,
        serviceEntry_date: null,
        working_date: null,
        out_of_service_date: null,
        calibration_plan: null,
        calibration_plan_path: null,
      },
      device_id: null,
      isEdit: null,
      lang_options: [
        { value: "ar", text: this.$t("g.Arabic") },
        { value: "en", text: this.$t("g.English") },
      ],
      breadCrumbItems: [
        {
          text: this.$t("g.home"),
          to: { name: "Home" },
        },
        {
          text: this.$t("g.equipmentList"),
          to: { name: "EquipmentList" },
        },
        {
          text: this.$t("g.addEquipment"),
          active: true,
        },
      ],
      countries_options: [
        {
          id: null,
          name: this.$t("g.selectCountry"),
          disabled: true,
        },
      ],
      categories_options: [
        {
          id: null,
          name: this.$t("g.selectCategory"),
          disabled: true,
        },
      ],
      supplier_opitons: [
        {
          id: null,
          name: this.$t("g.selectSupplier"),
          disabled: true,
        },
      ],
      maintenanceDuration_options: [
        {
          id: null,
          name: this.$t("g.selectMaintenanceDuration"),
          disabled: true,
        },
      ],
      electric_current_options: [
        {
          id: null,
          name: this.$t("g.selectelectricCurrent"),
          disabled: true,
        },
      ],
      region: "",
      subPermission: {},
      isLoading: true,
      isError: false,
    };
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.device_id = this.$route.params.id;
      this.getInfoDevice();
      this.isEdit = true;
    }
    this.getAllInfo();
  },
  methods: {
    getAllInfo() {
      this.$http
        .get("admin/categories")
        .then((res) => {
          this.categories_options.push(...res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });

      this.$http
        .get("admin/countries")
        .then((res) => {
          this.countries_options.push(...res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });

      this.$http
        .get("admin/electricInputTypes")
        .then((res) => {
          this.electric_current_options.push(...res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });

      this.$http
        .get("admin/suppliers")
        .then((res) => {
          this.supplier_opitons.push(...res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });

      this.$http
        .get("admin/maintenanceTypes", { params: { is_cycle: 1 } })
        .then((res) => {
          this.maintenanceDuration_options.push(...res.data.data);

          setTimeout(() => {
            this.isLoading = false;
            this.isError = false;
            store.dispatch("GET_PERMISSION", "devices");
            this.subPermission = store.state.permissions.sub;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getInfoDevice() {
      this.$http
        .get(`admin/devices/${this.$route.params.id}`)
        .then((res) => {
          if (res.status) {
            for (const key in res.data.data) {
              if (key === "category") {
                this.form_data.category_id = res.data.data.category.id;
              } else if (key === "made_in") {
                this.form_data.country_id = res.data.data.made_in.id;
              } else if (key === "electricInputType") {
                this.form_data.electric_input_type_id =
                  res.data.data.electricInputType.id;
              } else if (key === "supplier") {
                this.form_data.supplier_id = res.data.data.supplier.id;
              } else if (key === "maintenanceType") {
                this.form_data.maintenance_type_id =
                  res.data.data.maintenanceType.id;
              } else if (key === "calibration_plan") {
                this.form_data.calibration_plan_path =
                  res.data.data.calibration_plan.path;
                this.form_data.calibration_plan = null;
              } else {
                this.form_data[key] = res.data.data[key];
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //  Sweet Toast Function config
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    clearForm() {
      let form = this.form_data,
        i;
      for (i in form) {
        form[i] = null;
        if (i === "date") {
          form[i] = new Date();
        }
      }
    },
    validationForm() {
      delete this.form_data.calibration_reports;
      delete this.form_data.maintainProcess;
      console.log(this.form_data);
      if (this.form_data.calibration_plan === null) {
        delete this.form_data.calibration_plan;
      }
      this.$refs.EquipmentForm.validate().then((success) => {
        if (success) {
          let formData = new FormData();
          for (let item in this.form_data) {
            formData.append(item, this.form_data[item]);
          }

          if (this.isEdit) {
            this.$http
              .post(`admin/devices/${this.device_id}`, formData, {
                params: { _method: "put" },
              })
              .then((res) => {
                this.makeToast(
                  "success",
                  this.$t("g.send.successBody"),
                  this.$t("g.send.successTitle")
                );
                setTimeout(() => {
                  this.$router.push({ name: "EquipmentList" });
                }, 1000);
                this.clearForm();
                this.$refs.EquipmentForm.reset();
              })
              .catch((err) => {
                for (const key in err.response.data.errors) {
                  this.makeToast(
                    "danger",
                    err.response.data.errors[key][0],
                    this.$t("g.send.errorTitle")
                  );
                }
                console.log(err);
              });
          } else {
            this.$http
              .post("admin/devices", formData)
              .then((res) => {
                this.makeToast(
                  "success",
                  this.$t("g.send.successBody"),
                  this.$t("g.send.successTitle")
                );
                setTimeout(() => {
                  this.$router.push({ name: "EquipmentList" });
                }, 1000);
                this.clearForm();
                this.$refs.EquipmentForm.reset();
              })
              .catch((err) => {
                for (const key in err.response.data.errors) {
                  this.makeToast(
                    "danger",
                    err.response.data.errors[key][0],
                    this.$t("g.send.errorTitle")
                  );
                }
                console.log(err);
              });
          }
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
  },
};
</script>
